import React from "react";
import "./divider.scss";


const Divider = () => {

    return (
        <div className="divider" >
        </div>
    )
}

export default Divider; 