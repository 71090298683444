import React from "react";
import "./Newsletter.scss";
import { useHistory } from "react-router-dom";
import slack from "../images/slack.png";
import email from "../images/email.png";
import chat from "../images/chat.png"
import Activitycard from "../utilities/Activitycard/Activitycard";

function Newsletter() {
  const history = useHistory();
  const description1 = "Content includes design tech related articles, tutorials, inspirations, codebase, and more. Those content is generated by our organizers and members who want to recommend an article to other members.";
  const description2 = "We regularly invite club members to attend our Design Tech Meetups. Activities include company introductions, projects demos, lightning talks, socials and a lot more. This is a great way to meet design tech hybrid people across companies.";
  const description3 = "Our Slack workspace is a great corner for members to gather information such as new technologies, job postings, and networking opportunities! We regularly maintain the slack workspace and also post meetup announcements and updates there.";

  const getNewsLetter = () => {
    history.push("./news");
  }

  return (
    <div
      className="newsletter"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="news-title">✨ Activities</div>
      <div className="note"> Note: We currently pause the newletter and meetups due to organizer's availability conflict, but all activities will resume soon.</div>
      <div className="news-card">
        <Activitycard vector={email} title="Newsletter" description={description1} action="Previous Newsletters" method={getNewsLetter} hasButton={true} />
        <Activitycard vector={chat} title="Meetups" description={description2} />
        <Activitycard vector={slack} title="Slack Workspace" description={description3} hasButton={true} action="Learn More" method={() => { window.open("https://docs.google.com/forms/d/e/1FAIpQLScppa_I-N4kMWg1AveJfHElKcGb9pDoL-lSqjEZFGqrhZM5VQ/viewform?usp=sf_link") }} />
      </div>
      <div>
        <div>
        </div>
      </div>
    </div>
  );
}

export default Newsletter;
